import axios from "axios";

export const perkTracker = (id) => {
  const apiKey = process.env.REACT_APP_API_KEY;

  const apiKeyHeader = {
    headers: {
      "X-API-KEY": apiKey,
    },
  };

  axios.post("perksTracker.php", { id }, apiKeyHeader).catch((err) => {
    console.error(err);
  });
};
