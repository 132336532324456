import huddle_perk from "../images/huddle_perk.svg";
import huddle_perk_bg from "../images/huddle_perk_bg.svg";
import { perkTracker } from "../utils/perkTracker";

const PerkTile = ({ perk, large, setSelectedPerk }) => {
  return (
    <div
      className={large ? "perk-tile large" : "perk-tile bg-light border"}
      onClick={() => {
        setSelectedPerk(perk);
        perkTracker(perk.id);
      }}
    >
      {perk.bg_image && (
        <div className="perk-logo-container">
          <img src={perk.image} className="perk-logo-float" alt="perk" />
        </div>
      )}
      <div className="perk-bg-image-container">
        <img
          src={perk.bg_image ? perk.bg_image : perk.image}
          alt="perk-background-image"
          className="perk-bg-image"
          style={{
            objectFit: perk.bg_image ? "cover" : "contain"
          }}
        />
      </div>
      <div className="perk-tile-details">
        <div
          className="d-flex flex-row justify-content-between"
          style={{
            width: "80%",
            marginLeft: "auto",
            marginRight: "auto",
            height: "60%",
          }}
        >
          <div className="d-flex flex-row perk-deal-container">
            <p className="perk-deal">{perk.deal}</p>
          </div>
          <div className="huddle-perk-container">
            <img src={huddle_perk} className="huddle-perk" />
          </div>
        </div>

        <div
          className="d-flex flex-row justify-content-between pb-3"
          style={{
            width: "80%",
            marginLeft: "auto",
            marginRight: "auto",
            height: "40%",
          }}
        >
          <div
            style={{
              width: "55%",
            }}
          >
            <p
              style={{
                fontSize: 12,
              }}
            >
              {perk.company}
            </p>
          </div>
          <div
            style={{
              width: "40%",
            }}
          >
            <p
              style={{
                fontSize: 12,
                textAlign: "end",
              }}
            >
              {perk.redeem_route}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PerkTile;
