import { Button, Card, Form } from "react-bootstrap";
import "./css/ManagePerks.css";
import { useEffect, useState } from "react";
import PerkTile from "./PerkTile";
import PerkModal from "./PerkModal";

const ManagePerks = ({ perks, categories, redeemRoutes, cities }) => {
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [selectedCity, setSelectedCity] = useState("All");
  const [selectedPerk, setSelectedPerk] = useState();
  const [selectedRedeemRoutes, setSelectedRedeemRoutes] = useState([]);
  
  const filteredPerks = perks
    ?.filter(
      (perk) => selectedCategory === "All" || perk.category === selectedCategory
    )
    .filter((perk) => selectedCity === "All" || perk.city === selectedCity)
    .filter((perk) => {
      if (selectedRedeemRoutes.length === 0) {
        return perk;
      } else if (selectedRedeemRoutes.includes(perk.redeem_route)) {
        return perk;
      }
    });

  return (
    <div className="mb-5">
      <div
        className="categories py-3"
        style={{
          width: "90%",
          margin: "0 auto",
        }}
      >
        <Button
          onClick={() => {
            setSelectedCategory("All");
            setSelectedCity("All");
          }}
          className={
            selectedCategory === "All" && selectedCity === "All"
              ? "selected category"
              : "category"
          }
        >
          All
        </Button>
        {cities?.map((city) => {
          return (
            <Button
              onClick={() => {
                setSelectedCity(city);
                setSelectedCategory("All");
              }}
              className={
                selectedCity === city ? "selected category" : "category"
              }
              key={city}
            >
              {city}
            </Button>
          );
        })}
        {categories?.map((category) => {
          return (
            <Button
              onClick={() => {
                setSelectedCategory(category);
                setSelectedCity("All");
              }}
              className={
                selectedCategory === category ? "selected category" : "category"
              }
              key={category}
            >
              {category}
            </Button>
          );
        })}
      </div>
      <Card className="all-perks-container">
        <Card.Body>
          <div className="perks-section">
            <div className="d-flex flex-xl-row flex-column">
              <div
                style={{
                  width: "20%",
                  padding: "2vw",
                }}
                className="filters-container"
              >
                <h1 style={{ fontSize: 20, fontWeight: 600 }}>Filters</h1>
                <Form className="p-2">
                  {redeemRoutes?.map((redeemRoute) => (
                    <Form.Check
                      inline
                      className={"custom-checkbox"}
                      id={redeemRoute}
                      label={redeemRoute}
                      key={redeemRoute}
                      style={{
                        color: "black",
                        fontWeight: 500,
                        fontSize: 16,
                      }}
                      onChange={(event) => {
                        const value = event.target.id;

                        setSelectedRedeemRoutes((currentRedeemRoutes) => {
                          if (currentRedeemRoutes.includes(value)) {
                            return currentRedeemRoutes.filter(
                              (currentRedeemRoute) =>
                                currentRedeemRoute !== value
                            );
                          } else {
                            return [...currentRedeemRoutes, event.target.id];
                          }
                        });
                      }}
                    />
                  ))}
                </Form>
              </div>
              {perks.length === 0 ? (
                <div className="my-5 w-100">
                  <div className="justify-content-center d-flex">
                    No results found
                  </div>
                </div>
              ) : (
                <div
                  style={{
                    width: "80%",
                  }}
                  className="perks-container"
                >
                  <div className="all-perks">
                    {filteredPerks.length === 0 ? (
                      <div className="my-5">No results found</div>
                    ) : (
                      filteredPerks?.map((perk) => (
                        <PerkTile
                          perk={perk}
                          setSelectedPerk={setSelectedPerk}
                          key={perk.id}
                        />
                      ))
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </Card.Body>
      </Card>
      <PerkModal perk={selectedPerk} setSelectedPerk={setSelectedPerk} />
    </div>
  );
};

export default ManagePerks;
