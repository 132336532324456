import "./css/Gradient.css";
import bulb from "../images/bulb.svg";
import burger from "../images/burger.svg";
import lightning from "../images/lightning.svg";
import cash from "../images/cash.svg";
import price_tag from "../images/price_tag.svg";
import pizza from "../images/pizza.svg";
import { FloatingIcon } from "./FloatingIcon";

export const Gradient = ({ children }) => {
  return (
    <div className="gradient-container">
      <div className="floating-icon-container lightning-icon">
        <FloatingIcon icon={lightning} name={"lightning"} />
      </div>
      <div className="floating-icon-container bulb-icon">
        <FloatingIcon icon={bulb} name={"bulb"} />
      </div>
      <div className="floating-icon-container cash-icon">
        <FloatingIcon icon={cash} name={"cash"} />
      </div>
      <div className="gradient-children">{children}</div>
      <div className="floating-icon-container burger-icon">
        <FloatingIcon icon={burger} name={"burger"} />
      </div>
      <div className="floating-icon-container pizza-icon">
        <FloatingIcon icon={pizza} name={"pizza"} />
      </div>
      <div className="floating-icon-container price-tag-icon">
        <FloatingIcon icon={price_tag} name={"price-tag"} />
      </div>
    </div>
  );
};
