import { Gradient } from "./Gradient";
import "./css/PerkBanner.css";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";

import { Pagination } from "swiper/modules";
import {  useState } from "react";

import huddle_perk_bg from "../images/huddle_perk_bg.svg";
import PerkModal from "./PerkModal";
import { perkTracker } from "../utils/perkTracker";

export const PerkBanner = ({mostViewedPerks}) => {
  const [selectedPerk, setSelectedPerk] = useState(null)

  return (
    <div>
      <PerkModal perk={selectedPerk} setSelectedPerk={setSelectedPerk} />
      <Gradient>
        <Swiper pagination={true} modules={[Pagination]} className="mySwiper">
          {mostViewedPerks.map((perk) => {
            return (
              <SwiperSlide onClick={() => {
                setSelectedPerk(perk)
                perkTracker(perk.id)
                }} key={`${perk.id} banner`}>
                <div className="perk-banner-card">
                  <div className="perk-banner-details">
                    <div className="perk-banner-logo-container">
                      <img
                        src={perk.image}
                        alt={`${perk.company} logo`}
                        className="perk-banner-logo"
                      />
                    </div>
                    <div className="perk-banner-text-container">
                      <div className="perk-banner-company">{perk.company}</div>
                      <div className="perk-banner-description-container">
                        <div className="perk-banner-description">
                          {perk.huddle_description ?? perk.description}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="perk-banner-image-container">
                    <img
                      src={perk.bg_image ? perk.bg_image : perk.image}
                      alt={`${perk.company} background`}
                      className="perk-banner-bg-image"
                    />
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </Gradient>
    </div>
  );
};
