import React, { useEffect, useState } from "react";
import "./css/Home.css";
import ManagePerks from "../components/ManagePerks";
import { Spinner } from "react-bootstrap";
import axios from "axios";
import { PerkBanner } from "../components/PerkBanner";

const Home = () => {
  const [perks, setPerks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState();
  const [redeemRoutes, setRedeemRoutes] = useState(null);
  const [mostViewedPerks, setMostViewedPerks] = useState([]);
  const cities = ["Manchester", "Birmingham"];
  const apiKey = process.env.REACT_APP_API_KEY;

  const apiKeyHeader = {
    headers: {
      "X-API-KEY": apiKey,
    },
  };

  useEffect(() => {
    const perks = axios.get("perks.php", apiKeyHeader);
    const perkCategories = axios.get(`perkCategories.php`, apiKeyHeader);
    const perkRedeemRoutes = axios.get(`perkRedeemRoutes.php`, apiKeyHeader);
    const perkMostViewed = axios.get(`perksMostViewed.php`, apiKeyHeader);

    Promise.all([perkCategories, perkRedeemRoutes, perkMostViewed, perks])
      .then((result) => {
        setLoading(false);
        setCategories(result?.[0]?.data?.categories);
        setRedeemRoutes(result?.[1]?.data?.redeem_routes);
        setMostViewedPerks(result?.[2]?.data);
        setPerks(result?.[3].data);
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
      });
  }, []);

  return (
    <div className="home">
      {loading ? (
        <div className="loading">
          <Spinner />
        </div>
      ) : (
        <>
          {mostViewedPerks?.length > 0 ? (
            <PerkBanner mostViewedPerks={mostViewedPerks} />
          ) : (
            <div className="mt-4"></div>
          )}
          <ManagePerks
            perks={perks}
            categories={categories}
            redeemRoutes={redeemRoutes}
            cities={cities}
          />
        </>
      )}
    </div>
  );
};

export default Home;
