import "./css/HeaderTabs.css";


export const HeaderTabs = () => {

  return (
    <div className="header-tabs-container">
      <div className="header-tabs-title">
        <h1>Perks</h1>
      </div>
    </div>
  );
};
