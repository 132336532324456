import "./App.css";
import { HeaderTabs } from "./components/HeaderTabs";
import { Dashboard } from "./screens/Dashboard";
import Home from "./screens/Home";
import axios from "axios";
import { Route, Routes } from "react-router-dom";

function App() {
  axios.defaults.baseURL = process.env.REACT_APP_API_URL;
  axios.defaults.headers.post["Content-Type"] = "application/json";

  return (
    <div>
      <HeaderTabs />
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/dashboard" element={<Dashboard />}></Route>
      </Routes>
    </div>
  );
}

export default App;
