import { Button, Modal } from "react-bootstrap";
import "./css/PerkModal.css";
import huddle_perk_bg from "../images/huddle_perk_bg.svg";
import back from "../images/back.svg";
import new_tab from "../images/new_tab.svg";
import { useEffect, useState } from "react";

function generateCode() {
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  let result = '';
  
  for (let i = 0; i < 6; i++) {
    const randomIndex = Math.floor(Math.random() * chars.length);
    result += chars[randomIndex];
  }
  
  return result;
}

const PerkModal = ({ perk, setSelectedPerk }) => {
  return (
    <Modal
      show={perk}
      onHide={() => {
        setSelectedPerk(null);
      }}
    >
      <div
        className="perk-bg-image-container"
        style={{
          height: "35%",
          borderRadius: 0,
          borderTopLeftRadius: 35,
          borderTopRightRadius: 35,
        }}
      >
        <img
          src={perk?.bg_image ? perk?.bg_image : huddle_perk_bg}
          alt="perk-background-image"
          className="perk-bg-image"
          style={{
            borderRadius: 0,
            borderTopLeftRadius: 35,
            borderTopRightRadius: 35,
          }}
        />
      </div>
      <div className="single-perk-logo-container">
        <img src={perk?.image} className="single-perk-logo-float" alt="perk" />
      </div>

      <div className="d-flex flex-row justify-content-between modal-action-section">
        <Button
          style={{
            backgroundColor: "transparent",
            borderWidth: 0,
          }}
          className="modal-back"
        >
          <img
            src={back}
            onClick={() => {
              setSelectedPerk(null);
            }}
            style={{ height: "100%" }}
          />
        </Button>
      </div>

      <div className="modal-deal">{perk?.deal}</div>
      <div className="modal-redeem-route">{perk?.redeem_route}</div>
      <div className="modal-details">
        <div className="modal-description">
          {perk?.huddle_description ?? perk?.description}
        </div>
        <div className="modal-redeem-container">
          <div className="modal-redeem-section pb-3">
            <div style={{ fontWeight: 500 }} className="modal-redeem-title">
              How to redeem
            </div>
            <div
              style={{ marginTop: "2%" }}
              className="modal-redeem-description"
            >
              {perk?.instruction_long}
            </div>
          </div>
        </div>
      </div>
      <div className="redeem">
        <ActionButton perk={perk} />
      </div>
    </Modal>
  );
};

const ActionButton = ({ perk }) => {
  const [redeemed, setRedeemed] = useState(false);

  return (
    <a
      className="redeem"
      style={{ textDecoration: "none" }}
      href={perk?.type === "3" ? perk?.link : null}
      target={perk?.type === "3" ? "_blank" : null}
    >
      {!redeemed ? (
        <Button
          className="modal-button"
          style={{ height: "4vh" }}
          onClick={() => {
            if (perk.type === "1") {
              setRedeemed(true);
            }
          }}
        >
          {perk?.type === "3" ? (
            <div
              className="d-flex flex-row justify-content-between h-100"
              style={{ width: "100%", margin: "auto auto" }}
            >
              <p className="button-text">Open Link</p>
              <div className="ms-2 d-flex justify-content-center align-items-center">
                <img
                  src={new_tab}
                  alt={"open_in_new_tab"}
                  style={{ aspectRatio: 1, height: "70%", margin: "auto auto" }}
                />
              </div>
            </div>
          ) : (
            <div>
              <p className="button-text">Redeem</p>
            </div>
          )}
        </Button>
      ) : (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div className="redeem-message">CODE: {generateCode()}</div>
        </div>
      )}
    </a>
  );
};

export default PerkModal;
